import React from 'react'

import Layout from '../../hoc/layout/layout'
import SEO from '../../hoc/seo'
import Header404 from './404Header/Header404'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header404 />
  </Layout>
)

export default NotFoundPage
