import React from 'react'
import { withNamespaces } from 'react-i18next'
import '../../../i18n'
import classes from './Header404.module.scss'

const Header404 = ({ t }) => {
  return (
    <div className={classes.header404}>
      <div className={classes.title}>
        <h1>
          <span>Niestety nie znaleźliśmy tej strony</span>
        </h1>
      </div>
    </div>
  )
}

export default withNamespaces()(Header404)
